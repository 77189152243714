import styled from "styled-components";

export const MemberFormStyled = styled.div`
  margin-top: 20px;
  border-radius: 1rem;
  padding: 1.25rem;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .input_type_wrap {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    .itw_left_box {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .itw_right_box {
      width: 40%;
    }
  }
  .input_wrap {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    &.mt30 {
      margin-top: 30px;
    }
    .left_box {
      width: 20%;
      display: flex;
      align-items: center;
    }
    .right_box {
      width: 80%;
      display: flex;
      align-items: flex-start;
      .w50 {
        width: 50%;
      }
    }
  }
  .input_table_wrap {
    margin-bottom: 20px;
  }
  .array_item {
    margin-bottom: 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
    border-radius: 0.5rem;
    padding: 1rem 1rem 0.1rem 1rem;
  }
  .btn_add_wrap {
    margin: auto;
    margin-bottom: 1rem;
    width: 160px;
  }

  .btn_zone {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    .w120 {
      width: 120px;
    }
  }
`;
