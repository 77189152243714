import React, { useState, useMemo } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { DateGroupStyled } from './styled';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import moment from 'moment';
import { Inputs } from 'components';

const DateGroup = ({
  theme_standard,
  initialValues,
  iconSvgClose,
  iconSvgOpen,
  options,
  placeholder,
  onChange,
  onChangeCustom,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_level1Value, _setLevel1Value] = useState();
  const [_level2Value, _setLevel2Value] = useState();
  const [_renderFilter, _setRenderFilter] = useState();

  const _onChangeFilterLevel1 = (key) => {
    switch (key) {
      case 'day':
        _setRenderFilter(false);
        onChange(
          `start_date=${moment().format(
            'YYYY-MM-DD'
          )}&end_date=${moment().format('YYYY-MM-DD')}`
        );
        break;
      case 'quarter':
        _setRenderFilter(
          <Dropdown
            className="dg_input_container"
            controlClassName="input_dropdown_control"
            placeholderClassName="input_dropdown_placeholder"
            menuClassName="input_dropdown_menu"
            options={[
              {
                label: 'ไตรมาส1',
                value: `start_date=${moment()
                  .quarter(1)
                  .startOf('quarter')
                  .format('YYYY-MM-DD')}&end_date=${moment()
                  .quarter(1)
                  .endOf('quarter')
                  .format('YYYY-MM-DD')}`,
              },
              {
                label: 'ไตรมาส2',
                value: `start_date=${moment()
                  .quarter(2)
                  .startOf('quarter')
                  .format('YYYY-MM-DD')}&end_date=${moment()
                  .quarter(2)
                  .endOf('quarter')
                  .format('YYYY-MM-DD')}`,
              },
              {
                label: 'ไตรมาส3',
                value: `start_date=${moment()
                  .quarter(3)
                  .startOf('quarter')
                  .format('YYYY-MM-DD')}&end_date=${moment()
                  .quarter(3)
                  .endOf('quarter')
                  .format('YYYY-MM-DD')}`,
              },
              {
                label: 'ไตรมาส4',
                value: `start_date=${moment()
                  .quarter(4)
                  .startOf('quarter')
                  .format('YYYY-MM-DD')}&end_date=${moment()
                  .quarter(4)
                  .endOf('quarter')
                  .format('YYYY-MM-DD')}`,
              },
            ]}
            {...props}
            arrowClosed={
              <span className="arrow_icon arrow-closed">{iconSvgClose}</span>
            }
            arrowOpen={
              <span className="arrow_icon arrow-open">{iconSvgOpen}</span>
            }
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e.value);
              _setLevel2Value(e.value);
            }}
          />
        );
        break;
      case 'date_range':
        _setRenderFilter(
          <Inputs.InputDatePickerBetween
            startDatePlaceholder="เริ่ม"
            endDatePlaceholder={'สิ้นสุด'}
            onChange={(e) => {
              onChange(
                `date_range&start_date=${moment(e.startDate).format(
                  'YYYY-MM-DD'
                )}&end_date=${moment(e.endDate).format('YYYY-MM-DD')}`
              );
            }}
          />
        );
        break;
      case 'month':
        _setRenderFilter(
          <Inputs.DateTime
            startDatePlaceholder="เริ่ม"
            endDatePlaceholder={'สิ้นสุด'}
            showMonthYearPicker
            dateFormat="MM/yyyy"
            value={_level2Value}
            onChange={(e) => {
              _setLevel2Value(e);
              onChange(
                `month&start_date=${moment(e)
                  .startOf('month')
                  .format('YYYY-MM-DD')}&end_date=${moment(e)
                  .endOf('month')
                  .format('YYYY-MM-DD')}`
              );
            }}
          />
        );
        break;
      case 'year':
        _setRenderFilter(
          <Inputs.DateTime
            theme_standard
            startDatePlaceholder="เริ่ม"
            endDatePlaceholder={'สิ้นสุด'}
            showYearPicker
            dateFormat="yyyy"
            value={_level2Value}
            onChange={(e) => {
              _setLevel2Value(e);
              onChange(
                `year&start_date=${moment(e)
                  .startOf('year')
                  .format('YYYY-MM-DD')}&end_date=${moment(e)
                  .endOf('year')
                  .format('YYYY-MM-DD')}`
              );
            }}
          />
        );
        break;
      default:
        break;
    }
  };

  useMemo(() => {
    if (initialValues) {
      _setLevel1Value(initialValues.level1);
      if (initialValues.level2) {
        _setLevel2Value(initialValues.level2);
        _onChangeFilterLevel1(initialValues.level1);
      }
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateGroupStyled>
      <div className={customClass}>
        <div className="date_group_wrap">
          <div className="dg_input_wrap">
            <Inputs.Dropdown
              {...props}
              options={options}
              placeholder={placeholder}
              value={_level1Value}
              onChange={(e) => {
                _setLevel1Value(e);
                _onChangeFilterLevel1(e);
              }}
            />
          </div>
          {_renderFilter}
        </div>
      </div>
    </DateGroupStyled>
  );
};

DateGroup.propTypes = {};

export default DateGroup;
