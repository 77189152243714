import React from 'react';
import { AssetContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
// import { MdAdd } from 'react-icons/md';
// import momentTZ from "moment-timezone";
import queryString from 'query-string';
import {
  ASSET_TYPE,
  RENDER_ASSET_TYPE,
  RENDER_ASSET_STATUS,
  ASSET_SERVICE,
  RENDER_ASSET_SERVICE,
} from 'utils/functions/asset';
import { RENDER_COMMA } from 'utils/functions/FormatMoney';
import { Displays, Tables, Cards, Inputs } from 'components';

class AssetContainer extends React.Component {
  state = {
    isLoading: true,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_ASSET_LIST(stringQuery);
    if (res && res.status === 200) {
      console.log('res', res);
      this.setState({
        data: res.data.docs,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
          totalDocs: res.data.totalDocs,
        },
        isLoading: false,
      });
    }
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.ASSET}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.ASSET}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  render() {
    const { router } = this.props;
    const { isLoading, pagination, data } = this.state;
    return (
      <AssetContainerStyled>
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="score_row">
              <Cards.ScoreBlock
                label={'จำนวน Asset ทั้งหมด'}
                value={pagination.totalDocs}
              />
              {/* <Cards.ScoreBlock label={'จำนวน บ้าน'} value={'0'} /> */}
              {/* <Cards.ScoreBlock label={'จำนวน คอนโด'} value={'0'} /> */}
              {/* <Cards.ScoreBlock label={'จำนวน ที่ดิน'} value={'0'} /> */}
              {/* &nbsp;&nbsp;
              <div className="score_block_container">
                <text>แผนที่ประเทศไทย</text>
                <div className="sb_body">
                  <img
                    alt="MiSUMi logo"
                    src="/assets/images/thailand.jpg"
                    style={{ height: 200, width: 200 }}
                  />
                </div>
              </div> */}
            </div>
            {
              <div className="body_table">
                <Tables.Standard
                  columns={columns({
                    handleClickDetail: this.handleClickCreate,
                  })}
                  data={data}
                  showPagination
                  pagination={pagination}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            }
          </>
        )}
      </AssetContainerStyled>
    );
  }
}

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>Name</div>,
    accessor: 'title',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Service</div>,
    accessor: 'service',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_ASSET_SERVICE(props.value).label : ''}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Type</div>,
    accessor: 'type',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_ASSET_TYPE(props.value).label : ''}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Address</div>,
    accessor: 'province',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {`${props.value || ''} ${props.row.original.district || ''} ${
          props.row.original.sub_district || ''
        } ${props.row.original.zipcode || ''}`}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Price</div>,
    accessor: 'asset_price',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RENDER_COMMA(props.value)}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Status</div>,
    accessor: 'status',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {RENDER_ASSET_STATUS(props.value).label}
      </div>
    ),
  },
];

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: 'name',
      length: '311px',
      filter: {
        fieldQuery: 'name',
        defaultValue: initialValues['name'],
        type: 'text',
        label: 'Name',
        placeholder: 'Name',
      },
    },
    {
      accessor: 'service',
      length: '311px',
      filter: {
        fieldQuery: 'service',
        defaultValue: initialValues['service'],
        type: 'dropdown',
        label: 'Service',
        placeholder: '',
        options: ASSET_SERVICE,
      },
    },
    {
      accessor: 'type',
      length: '311px',
      filter: {
        fieldQuery: 'type',
        defaultValue: initialValues['type'],
        type: 'dropdown',
        label: 'Type',
        placeholder: '',
        options: ASSET_TYPE,
      },
    },
    {
      accessor: 'province',
      length: '311px',
      filter: {
        fieldQuery: 'province',
        defaultValue: initialValues['province'],
        type: 'text',
        label: 'Province',
        placeholder: 'Province',
      },
    },
    {
      accessor: 'district',
      length: '311px',
      filter: {
        fieldQuery: 'district',
        defaultValue: initialValues['district'],
        type: 'text',
        label: 'District',
        placeholder: 'District',
      },
    },
    {
      accessor: 'sub_district',
      length: '311px',
      filter: {
        fieldQuery: 'sub_district',
        defaultValue: initialValues['sub_district'],
        type: 'text',
        label: 'Sub district',
        placeholder: 'Sub district',
      },
    },
    {
      accessor: 'zipcode',
      length: '311px',
      filter: {
        fieldQuery: 'zipcode',
        defaultValue: initialValues['zipcode'],
        type: 'text',
        label: 'Zipcode',
        placeholder: 'Zipcode',
      },
    },
  ];
};

export default withRouter(AssetContainer);
