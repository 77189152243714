import styled from "styled-components";

export const TransactionCoinFormStyled = styled.div`
  .body_form_campaign {
    margin-top: 20px;
    border-radius: 1rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .input_type_wrap {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      .itw_left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .itw_right_box {
        width: 40%;
      }
    }
    .input_wrap {
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      &.mt30 {
        margin-top: 30px;
      }
      .left_box {
        width: 20%;
        display: flex;
        align-items: center;
      }
      .right_box {
        width: 80%;
        display: flex;
        align-items: flex-start;
        .w50 {
          width: 50%;
        }
      }
    }
    .btn_zone {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      .w120 {
        width: 120px;
      }
    }
  }
`;
