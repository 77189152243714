export const TRANSACTION_METHOD = [
  { label: 'ฝาก', value: '1' },
  { label: 'ถอน', value: '2' },
];

export const RENDER_TRANSACTION_METHOD = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ฝาก',
      };
    case '2':
      return {
        label: 'ถอน',
      };
    default:
      break;
  }
};

export const TRANSACTION_ACTION_TYPE = [
  { label: 'เติมเข้ามา', value: '1' },
  { label: 'ทำธุรกรรมแชร์ทรัพย์', value: '2' },
  { label: 'ทำธุรกรรมโคทรัพย์', value: '3' },
];

export const RENDER_ACTION_TYPE = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'เติมเข้ามา',
      };
    case '2':
      return {
        label: 'ทำธุรกรรมแชร์ทรัพย์',
      };
    case '3':
      return {
        label: 'ทำธุรกรรมโคทรัพย์',
      };

    default:
      break;
  }
};

export const TRANSACTION_STATUS = [
  { label: 'รออนุมัติ', value: '1' },
  { label: 'ปฏิเสธ', value: '2' },
  { label: 'อนุมัติ', value: '3' },
];

export const RENDER_TRANSACTION_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'รออนุมัติ',
      };
    case '2':
      return {
        label: 'ปฏิเสธ',
      };
    case '3':
      return {
        label: 'อนุมัติ',
      };
    default:
      break;
  }
};


export const TRANSACTION_C_ACTION_TYPE = [
  { label: 'ลงทรัพย์', value: '1' },
  { label: 'ทำธุรกรรมแชร์ทรัพย์', value: '2' },
  { label: 'ทำธุรกรรมโคทรัพย์', value: '3' },
];

export const RENDER_C_ACTION_TYPE = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ลงทรัพย์',
      };
    case '2':
      return {
        label: 'ทำธุรกรรมแชร์ทรัพย์',
      };
    case '3':
      return {
        label: 'ทำธุรกรรมโคทรัพย์',
      };

    default:
      break;
  }
};