import styled from 'styled-components';

export const LoginContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  .form_layout {
    margin: auto;
    border-radius: 0.3125rem;
    padding: 3.125rem 3.125rem;
    width: 100%;
    max-width: calc(540px - 3.125rem);
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
