import styled from "styled-components";

export const SystemLayoutStyled = styled.div`
  min-width: 1180px;
  min-height: 100vh;
  display: flex;
  .right_mainlayout {
    flex: 1;
    padding: 1.75rem 2.5rem;
    background: ${({ theme }) => theme.COLORS.GRAY_4};
  }
`;
