import React from "react";
import { AdminFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const AdminForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    admin_name: yup.string().required("โปรดกรอกชื่อ"),
    username: yup.string().required("โปรดกรอก Username"),
    admin_access: yup.string().required("โปรดเลือกสิทธิการเข้าถึง"),
    admin_status: yup.string().required("โปรดเลือกสถานะ"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <AdminFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Name:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.admin_name?.message} />}
                name="admin_name"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Username:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.username?.message} />}
                name="username"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="input_type_wrap">
          <div className="itw_left_box">สิทธิการเข้าถึง :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "สามารถเข้าถึงได้เฉพาะหน้าUser",
                    },
                  ]}
                  errors={errors.admin_access?.message}
                />
              )}
              name="admin_access"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>

        <div className="input_type_wrap">
          <div className="itw_left_box">Status :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "In-Review",
                    },
                    {
                      value: "2",
                      label: "Active",
                    },
                    {
                      value: "3",
                      label: "Inactive",
                    },
                    {
                      value: "4",
                      label: "Cancel",
                    },
                  ]}
                  errors={errors.admin_status?.message}
                />
              )}
              name="admin_status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard theme_red label="Delete" disabled={initialValues && initialValues.is_center} onClick={onDelete} />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </AdminFormStyled>
  );
};

AdminForm.propTypes = {};

export default AdminForm;
