import { ROUTE_PATH } from 'utils/constants/routePath';
import theme from 'styles/theme.json';
import TopTab from 'components/Navigations/TopTab';
import {
  // MdDashboard,
  MdPeople,
  MdWebAsset,
  //  MdPayments,
  MdContactPage,
  MdReorder,
  // MdAnalytics, MdSettings, MdAdsClick
} from 'react-icons/md';
// import DashboardContainer from 'containers/DashboardContainer';
// import AdminContainer from "containers/AdminSystem/AdminContainer";
// import AdminDetailContainer from "containers/AdminSystem/AdminDetailContainer";
// import AdvertingContainer from "containers/AdvertingSystem/AdvertingContainer";
// import AdvertingDetailContainer from "containers/AdvertingSystem/AdvertingDetailContainer";
import MemberContainer from 'containers/MemberSystem/MemberContainer';
// import PaymentContainer from "containers/PaymentSystem/PaymentContainer";
// import PaymentDetailContainer from "containers/PaymentSystem/PaymentDetailContainer";
// import ValueVolumeContainer from "containers/ValueVolumeSystem/ValueVolumeContainer";
// import ValueVolumeDetailContainer from "containers/ValueVolumeSystem/ValueVolumeDetailContainer";
import TransactionCoinContainer from 'containers/TransactionSystem/TransactionCoinContainer';
import TransactionCoinDetailContainer from 'containers/TransactionSystem/TransactionCoinDetailContainer';
import TransactionCreditContainer from 'containers/TransactionSystem/TransactionCreditContainer';
import TransactionCreditDetailContainer from 'containers/TransactionSystem/TransactionCreditDetailContainer';
import AssetContainer from 'containers/AssetSystem/AssetContainer';
import AssetDetailContainer from 'containers/AssetSystem/AssetDetailContainer';
import SmartContractContainer from 'containers/SmartContractSystem/SmartContractContainer';
import SmartContractDetailContainer from 'containers/SmartContractSystem/SmartContractDetailContainer';
import MemberDetailContainer from 'containers/MemberSystem/MemberDetailContainer';

const systemRoutes = [
  // {
  //   key: 'dashboard',
  //   name: 'Dashboard',
  //   path: ROUTE_PATH.DASHBOARD,
  //   icon: (active) => (
  //     <MdDashboard
  //       size={28}
  //       color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
  //     />
  //   ),
  //   element: <DashboardContainer />,
  // },
  {
    key: 'member',
    name: 'User',
    path: ROUTE_PATH.MEMBER,
    icon: (active) => (
      <MdPeople
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <MemberContainer />,
  },
  {
    key: 'member',
    name: 'User',
    path: ROUTE_PATH.MEMBER_DETAIL,
    icon: (active) => (
      <MdPeople
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    notShowSidebar: true,
    element: <MemberDetailContainer />,
  },
  {
    key: 'asset',
    name: 'Asset',
    path: ROUTE_PATH.ASSET,
    icon: (active) => (
      <MdWebAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <AssetContainer />,
  },
  {
    key: 'asset',
    name: 'Asset',
    path: ROUTE_PATH.ASSET_DETAIL,
    icon: (active) => (
      <MdWebAsset
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    notShowSidebar: true,
    element: <AssetDetailContainer />,
  },
  // {
  //   key: "payment",
  //   name: "Payment",
  //   path: ROUTE_PATH.PAYMENT,
  //   icon: (active) => <MdPayments size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   element: <PaymentContainer />,
  // },
  // {
  //   key: "payment",
  //   name: "Payment",
  //   path: ROUTE_PATH.PAYMENT_DETAIL,
  //   icon: (active) => <MdPayments size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   notShowSidebar: true,
  //   element: <PaymentDetailContainer />,
  // },
  {
    key: 'contract',
    name: 'Smart Contract',
    path: ROUTE_PATH.CONTRACT,
    icon: (active) => (
      <MdContactPage
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: <SmartContractContainer />,
  },
  {
    key: 'contract',
    name: 'Smart Contract',
    path: ROUTE_PATH.CONTRACT_DETAIL,
    icon: (active) => (
      <MdContactPage
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    notShowSidebar: true,
    element: <SmartContractDetailContainer />,
  },
  {
    key: 'transaction',
    name: 'Transaction',
    path: ROUTE_PATH.TRANSACTION,
    icon: (active) => (
      <MdReorder
        size={28}
        color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2}
      />
    ),
    element: (
      <TopTab
        data={[
          {
            key: 'coin',
            name: 'Merge Coin',
            path: ROUTE_PATH.TRANSACTION_COIN,
            element: <TransactionCoinContainer />,
          },
          {
            key: 'credit',
            name: 'Merge Credit Point',
            path: ROUTE_PATH.TRANSACTION_CREDIT,
            element: <TransactionCreditContainer />,
          },
        ]}
      />
    ),
    children: [
      {
        key: 'coin',
        name: 'Merge Coin',
        path: ROUTE_PATH.TRANSACTION_COIN,
        element: <TransactionCoinContainer />,
      },
      {
        key: 'coin',
        name: 'Merge Coin',
        path: ROUTE_PATH.TRANSACTION_COIN_DETAIL,
        element: <TransactionCoinDetailContainer />,
      },
      {
        key: 'credit',
        name: 'Merge Credit Point',
        path: ROUTE_PATH.TRANSACTION_CREDIT,
        element: <TransactionCreditContainer />,
      },
      {
        key: 'credit',
        name: 'Merge Credit Point',
        path: ROUTE_PATH.TRANSACTION_CREDIT_DETAIL,
        element: <TransactionCreditDetailContainer />,
      },
    ],
  },
  // {
  //   key: "value",
  //   name: "Value Volume",
  //   path: ROUTE_PATH.VALUE,
  //   icon: (active) => <MdAnalytics size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   element: <ValueVolumeContainer />,
  // },
  // {
  //   key: "value",
  //   name: "Value Volume",
  //   path: ROUTE_PATH.VALUE_DETAIL,
  //   icon: (active) => <MdAnalytics size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   notShowSidebar: true,
  //   element: <ValueVolumeDetailContainer />,
  // },
  // {
  //   key: "adverting",
  //   name: "Adverting",
  //   path: ROUTE_PATH.ADVERTING,
  //   icon: (active) => <MdAdsClick size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   element: <AdvertingContainer />,
  // },
  // {
  //   key: "adverting",
  //   name: "Adverting",
  //   path: ROUTE_PATH.ADVERTING_DETAIL,
  //   notShowSidebar: true,
  //   icon: (active) => <MdAdsClick size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   element: <AdvertingDetailContainer />,
  // },
  // {
  //   key: "admin",
  //   name: "Admin setting",
  //   path: ROUTE_PATH.ADMIN,
  //   icon: (active) => <MdSettings size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   element: <AdminContainer />,
  // },
  // {
  //   key: "admin",
  //   name: "Admin setting",
  //   path: ROUTE_PATH.ADMIN_DETAIL,
  //   icon: (active) => <MdSettings size={28} color={active ? theme.COLORS.BLUE_4 : theme.COLORS.BLUE_2} />,
  //   notShowSidebar: true,
  //   element: <AdminDetailContainer />,
  // },
];

export default systemRoutes;
