import styled from "styled-components";

export const MemberDetailContainerStyled = styled.div`
  .loading_show {
    width: 100%;
    height: calc(100vh - 175px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
