export const CO_AGENT_CONTRACT = [
  { label: 'ตรวจสอบ', value: '1' },
  { label: 'จอง', value: '2' },
  { label: 'ทำสัญญา', value: '3' },
  { label: 'ธุรกรรม', value: '4' },
  { label: 'โอนกรรมสิทธิ์', value: '5' },
  { label: 'สำเร็จ', value: '6' },
  { label: 'สำเร็จ', value: '7' },
];

export const RENDER_CO_AGENT_CONTRACT = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ตรวจสอบ',
      };
    case '2':
      return {
        label: 'จอง',
      };
    case '3':
      return {
        label: 'ทำสัญญา',
      };
    case '4':
      return {
        label: 'ธุรกรรม',
      };
    case '5':
      return {
        label: 'โอนกรรมสิทธิ์',
      }
    case '6':
      return {
        label: 'สำเร็จ',
      };
    case '7':
      return {
        label: 'สำเร็จ',
      };

    default:
      break;
  }
};
