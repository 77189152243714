import React from "react";
import { ValueVolumeFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const ValueVolumeForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    date: yup.string().required("โปรดกรอกวันที่"),
    contract_id: yup.string().required("โปรดกรอก ID"),
    username: yup.string().required("โปรดกรอก Username"),
    value: yup.string().required("โปรดกรอก Value"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <ValueVolumeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Date:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.DateTime {...field} showYearDropdown showMonthDropdown errors={errors.date?.message} />}
                name="date"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="input_wrap">
          <div className="left_box">Smart Contract ID:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.contract_id?.message} />}
                name="contract_id"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Username:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.username?.message} />}
                name="username"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Value:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.value?.message} />}
                name="value"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard theme_red label="Delete" disabled={initialValues && initialValues.is_center} onClick={onDelete} />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </ValueVolumeFormStyled>
  );
};

ValueVolumeForm.propTypes = {};

export default ValueVolumeForm;
