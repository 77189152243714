export const ASSET_TYPE = [
  { label: 'บ้าน', value: '1' },
  { label: 'คอนโด', value: '2' },
  { label: 'ที่ดิน', value: '3' },
  { label: 'ทาวน์โฮม', value: '4' },
  { label: 'อาคารพาณิชย์', value: '5' },
  { label: 'โรงแรม', value: '6' },
  { label: 'โกดัง/โรงงาน', value: '7' },
  { label: 'อพาร์ทเมนท์/หอพัก', value: '8' },
  { label: 'สำนักงาน/ออฟฟิต', value: '9' },
];

export const RENDER_ASSET_TYPE = (key) => {
  switch (key) {
    case '1':
      return { label: 'บ้าน' };
    case '2':
      return { label: 'คอนโด' };
    case '3':
      return { label: 'ที่ดิน' };
    case '4':
      return { label: 'ทาวน์โฮม' };
    case '5':
      return { label: 'อาคารพาณิชย์' };
    case '6':
      return { label: 'โรงแรม' };
    case '7':
      return { label: 'โกดัง/โรงงาน' };
    case '8':
      return { label: 'อพาร์ทเมนท์/หอพัก' };
    case '9':
      return { label: 'สำนักงาน/ออฟฟิต' };
    default:
      return { label: '' };
  }
};

export const RENDER_ASSET_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ลบ',
      };
    case '2':
      return {
        label: 'ขาย',
      };

    case '3':
      return {
        label: 'ดำเนินการ',
      };

    case '4':
      return {
        label: 'ปิดการขาย',
      };

    default:
      break;
  }
};

export const ASSET_SERVICE = [
  { label: 'ขาย', value: '1' },
  { label: 'เข่า', value: '2' },
  { label: 'ขาย/เช่า', value: '3' },
];

export const RENDER_ASSET_SERVICE = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ขาย',
      };
    case '2':
      return {
        label: 'เช่า',
      };
    case '3':
      return {
        label: 'ขาย/เช่า',
      };
    default:
      return {
        label: '',
      };
  }
};
