import styled from 'styled-components';

export const SidebarWidgetStyled = styled.div`
  min-height: 100%;
  width: ${({ isExpand }) => (isExpand ? '238px' : '50px')};
  background: ${({ theme }) => theme.COLORS.BLUE_5};
  transition: width 1s;
  .btn_expand {
    position: absolute;
    left: 4px;
    padding: 5px;
    cursor: pointer;
    transform: ${({ isExpand }) =>
      isExpand ? 'rotate(0deg)' : 'rotate(180deg)'};
    transition: transform 1s;
  }
  .s_logo_sec {
    padding: ${({ isExpand }) =>
      isExpand ? '2.5rem 1rem 1rem 1rem' : '2.5rem 0.25rem 1rem 0.25rem'};
    text-align: center;
    .sidebar_logo {
      width: ${({ isExpand }) => (isExpand ? '70px' : '40px')};
      height: auto;
    }
  }
  .s_menu_sec {
    margin: 0px;
    padding: 0px;
    list-style: none;
    .s_menu_item {
      padding: ${({ isExpand }) => (isExpand ? '1rem 1.5rem' : '1rem 0.75rem')};
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      cursor: pointer;
      .s_icon_wrap {
        flex-shrink: 0;
      }
      .s_label {
        display: ${({ isExpand }) => (isExpand ? 'block' : 'none')};
      }
    }
  }
  .avatar_nav_wrap {
    overflow: ${({ isExpand }) => (isExpand ? 'hidden' : 'none')};
    padding: ${({ isExpand }) => (isExpand ? '1rem 1.5rem;' : '1rem 0.5rem')};
    background: ${({ theme }) => theme.COLORS.BLUE_1};
  }
`;
