import React, { useEffect } from "react";
import { PaymentFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const PaymentForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    created_at: yup.string().required("โปรดกรอกวันที่"),
    status: yup.string().required("โปรดเลือก Status"),
    // payment_cost: yup.string().required("โปรดกรอก cost"),
    // username: yup.string().required("โปรดกรอก Username"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <PaymentFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Date :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.DateTime {...field} showYearDropdown showMonthDropdown errors={errors.created_at?.message} />}
                name="created_at"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Username:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.username?.message} />}
                name="username"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">cost:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.payment_cost?.message} />}
                name="payment_cost"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_type_wrap">
          <div className="itw_left_box">Status :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "1",
                    },
                    {
                      value: "2",
                      label: "2",
                    },
                    {
                      value: "3",
                      label: "3",
                    },
                  ]}
                  errors={errors.status?.message}
                />
              )}
              name="status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard theme_red label="Delete" disabled={initialValues && initialValues.is_center} onClick={onDelete} />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </PaymentFormStyled>
  );
};

PaymentForm.propTypes = {};

export default PaymentForm;
