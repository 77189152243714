import { PageLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
  return (
    <PageLayoutStyled>
      <Outlet />
    </PageLayoutStyled>
  );
};

export default PageLayout;
