import React, { useEffect } from 'react';
import { TransactionCreditFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const TransactionCreditForm = ({
  onSubmit,
  onCancel,
  onDelete,
  initialValues,
}) => {
  const schema = yup.object().shape({
    status: yup.string().required('กรุณาเลือกสถานะ'),
  });

  const {
    control,
    // watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const watchCampaignType = watch('type');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TransactionCreditFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">สถานะ</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={[
                      {
                        value: '1',
                        label: 'waiting approve',
                      },
                      {
                        value: '2',
                        label: 'reject',
                      },
                      {
                        value: '3',
                        label: 'success',
                      },
                    ]}
                    errors={errors.status?.message}
                  />
                )}
                name="status"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard
                theme_gray
                label="Cancel"
                onClick={onCancel}
              />
            </div>
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                // disabled={initialValues && !initialValues.is_edit}
              />
            </div>
          </div>
        </div>
      </form>
    </TransactionCreditFormStyled>
  );
};

TransactionCreditForm.propTypes = {};

export default TransactionCreditForm;
