import styled from 'styled-components';

export const LoginFormStyled = styled.div`
  .logo_wrap {
    text-align: center;
    .logo {
      margin-bottom: 1rem;
      width: 220px;
      height: auto;
    }
  }
  .title_form {
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
  }
  .input_wrap {
    margin-bottom: 0.75rem;
    &.mb_2 {
      margin-bottom: 2rem;
    }
  }
`;
