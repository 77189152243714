import React from 'react';
import { SmartContractContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import momentTZ from 'moment-timezone';
import queryString from 'query-string';
import {
  RENDER_CO_AGENT_CONTRACT,
  CO_AGENT_CONTRACT,
} from 'utils/functions/coAgent';
import { Displays, Tables, Inputs, Cards } from 'components';

class SmartContractContainer extends React.Component {
  state = {
    isLoading: true,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_CO_AGENT_CONTRACT_LIST(stringQuery);
    if (res && res.status === 200) {
      console.log('res', res);
      this.setState({
        data: res.data.docs,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
          totalDocs: res.data.totalDocs,
        },
        isLoading: false,
      });
    }
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleClickCreate = (e) => {
    // this.props.router.navigate(`${ROUTE_PATH.CONTRACT}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.CONTRACT}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  render() {
    const { router } = this.props;
    const { isLoading, pagination, data } = this.state;
    return (
      <SmartContractContainerStyled>
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
          onExport
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="score_row">
              <Cards.ScoreBlock
                label={'จำนวนสัญญาในระบบ'}
                value={pagination?.totalDocs}
              />
            </div>
            <div className="body_table">
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </SmartContractContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: 'status',
      length: '311px',
      filter: {
        fieldQuery: 'status',
        defaultValue: initialValues['status'],
        type: 'dropdown',
        label: 'สถานะ',
        placeholder: '',
        options: CO_AGENT_CONTRACT,
      },
    },
  ];
};

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>Asset</div>,
    accessor: 'asset_detail.title',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Name</div>,
    accessor: 'share_broker_detail.first_name',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {`${props.value} ${props.row.original.share_broker_detail.last_name}`}
      </div>
    ),
  },

  {
    Header: <div style={{ textAlign: 'left' }}>Created date</div>,
    accessor: 'username_date',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {momentTZ(props.value).tz('Asia/Bangkok').format('DD/MM/YYYY ')}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>Status</div>,
    accessor: 'status',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_CO_AGENT_CONTRACT(props.value).label : ''}
      </div>
    ),
  },
];

export default withRouter(SmartContractContainer);
