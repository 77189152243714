import { SystemLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { SidebarWidget } from "widgets";

const SystemLayout = () => {
  return (
    <SystemLayoutStyled>
      <div className="left_mainlayout">
        <SidebarWidget />
      </div>
      <div className="right_mainlayout">
        <Outlet />
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
