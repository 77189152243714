import React from 'react';
import { InputCheckBoxStyled } from './styled';
import cx from 'classnames';

export const InputCheckBox = ({
  theme_standard_input_check_box,
  label,
  value,
  checked,
  fontSize,
  fontFamily,
  fontColor,
  onChange,
}) => {
  const customClass = cx({
    theme_standard_input_check_box: theme_standard_input_check_box,
  });

  return (
    <InputCheckBoxStyled
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
    >
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={value}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          <span className="checkmark"></span>
          {label &&
            <span className='lable_show'>
              {label}
            </span>
          }
        </label>
      </div>
    </InputCheckBoxStyled>
  );
};

export default InputCheckBox;
