import React from "react";
import { SmartContractDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms } from "components";

class SmartContractDetailContainer extends React.Component {
  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.CONTRACT);
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  render() {
    return (
      <SmartContractDetailContainerStyled>
        <Forms.SmartContractForm onSubmit={this.handleSubmit} onCancel={this.handleCancel} />
      </SmartContractDetailContainerStyled>
    );
  }
}

export default withRouter(SmartContractDetailContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(AaPlatContainer)));
