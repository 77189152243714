import apiService from '../apiService';

const apiPath = '/api/v1';

export const portalService = {
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login-admin`, params);
  },
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPath}/admin/myProfile`);
  },

  GET_BROKER_LIST: (stringQuery) => {
    return apiService.get(`${apiPath}/broker${stringQuery ? stringQuery : ''}`);
  },

  GET_BROKER_DETAIL: (id) => {
    return apiService.get(`${apiPath}/broker/${id}`);
  },
  POST_BROKER_CREATE: () => {
    return apiService.post(`${apiPath}/broker`);
  },
  PUT_BROKER_UPDATE: (id, params) => {
    return apiService.put(`${apiPath}/broker/${id}`, params);
  },

  GET_ASSET_LIST: (stringQuery) => {
    return apiService.get(`${apiPath}/asset${stringQuery ? stringQuery : ''}`);
  },

  GET_ASSET_DETAIL: (id) => {
    return apiService.get(`${apiPath}/asset/${id}`);
  },
  DELETE_ASSET_DETAIL: (id) => {
    return apiService.delete(`${apiPath}/asset/${id}`);
  },

  POST_ASSET_CREATE: () => {
    return apiService.post(`${apiPath}/asset`);
  },

  GET_PAYMENT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/mergecoin-transaction${stringQuery ? stringQuery : ''}`
    );
  },
  GET_PAYMENT_DETAIL: (id) => {
    return apiService.get(`${apiPath}/mergecoin-transaction/${id}`);
  },
  POST_PAYMENT_CREATE: () => {
    return apiService.post(`${apiPath}/payment`);
  },

  // transaction Discount History Code
  GET_TRANSACTION_DISCOUNT_HISTORY_CODE_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/transaction_discount_history${stringQuery ? stringQuery : ''}`
    );
  },

  GET_MERGE_COIN_TRANSACTION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/mergecoin-transaction${stringQuery ? stringQuery : ''}`
    );
  },
  GET_MERGE_COIN_TRANSACTION_DETAIL: (id) => {
    return apiService.get(`${apiPath}/mergecoin-transaction/${id}`);
  },
  PUT_MERGE_COIN_TRANSACTION_UPDATE: (id, params) => {
    return apiService.put(
      `${apiPath}/mergecoin-transaction/approve/${id}`,
      params
    );
  },

  GET_CREDIT_POINT_TRANSACTION_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/creditpoint-transaction${stringQuery ? stringQuery : ''}`
    );
  },
  GET_CREDIT_POINT_TRANSACTION_DETAIL: (id) => {
    return apiService.get(`${apiPath}/creditpoint-transaction/${id}`);
  },

  GET_CO_AGENT_CONTRACT_LIST: (stringQuery) => {
    return apiService.get(
      `${apiPath}/co-agent-contract${stringQuery ? stringQuery : ''}`
    );
  },
};
