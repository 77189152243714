import AdminForm from "./AdminForm";
import AssetForm from "./AssetForm";
import AdvertingForm from "./AdvertingForm";
import LoginForm from "./LoginForm";
import MemberForm from "./MemberForm";
import PaymentForm from "./PaymentForm";
import SmartContractForm from "./SmartContractForm";
import TransactionCoinForm from "./TransactionCoinForm";
import TransactionCreditForm from "./TransactionCreditForm";
import ValueVolumeForm from "./ValueVolumeForm";

const EXPORT = {
  AdminForm,
  AssetForm,
  AdvertingForm,
  LoginForm,
  MemberForm,
  PaymentForm,
  SmartContractForm,
  TransactionCoinForm,
  TransactionCreditForm,
  ValueVolumeForm,
};

export default EXPORT;
