export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  COMPONENTS: "/components",
  SYSTEM: "/system",
  DASHBOARD: "/system/dashboard",
  ADMIN: "/system/admin",
  ADMIN_DETAIL: "/system/admin/:id",
  MEMBER: "/system/member",
  MEMBER_DETAIL: "/system/member/:id",
  PAYMENT: "/system/payment",
  PAYMENT_DETAIL: "/system/payment/:id",
  CONTRACT: "/system/contract",
  CONTRACT_DETAIL: "/system/contract/:id",
  VALUE: "/system/value",
  VALUE_DETAIL: "/system/value/:id",
  ADVERTING: "/system/adverting",
  ADVERTING_DETAIL: "/system/adverting/:id",
  TRANSACTION: "/system/transaction",
  TRANSACTION_COIN: "/system/transaction/coin",
  TRANSACTION_COIN_DETAIL: "/system/transaction/coin/:id",
  TRANSACTION_CREDIT: "/system/transaction/credit",
  TRANSACTION_CREDIT_DETAIL: "/system/transaction/credit/:id",
  ASSET: "/system/asset",
  ASSET_DETAIL: "/system/asset/:id",
  AGENT: "/system/agent",
};
