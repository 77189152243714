export const NATIONALITY = [
  {
    label: 'ไทย',
    value: '1',
  },
  {
    label: 'จีน',
    value: '2',
  },
  {
    label: 'ต่างชาติ',
    value: '3',
  },
];

export const RENDER_NATIONALITY = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ไทย',
      };
    case '2':
      return {
        label: 'จีน',
      };
    case '3':
      return {
        label: 'ต่างชาติ',
      };
    default:
      break;
  }
};
