import ArrowDropdown from './ArrowDropdown';
import CloseX from './CloseX';
import AddRound from './AddRound';
import Loading from './Loading';
import ArrowLeftRight from './ArrowLeftRight';

const EXPORT = {
  ArrowDropdown,
  AddRound,
  CloseX,
  Loading,
  ArrowLeftRight,
};

export default EXPORT;
