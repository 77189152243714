import styled from "styled-components";

export const MemberContainerStyled = styled.div`
  .score_row {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }

  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .btn_create {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      .w120 {
        margin-left: 8px;
        width: 120px;
      }
    }
  }
`;
