import React from "react";
import { SmartContractFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const SmartContractForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    username: yup.string().required("โปรดกรอก Username"),
    type_contract: yup.string().required("โปรดกรอก Type"),
    username_date: yup.string().required("โปรดกรอกวันที่"),
    username_contract: yup.string().required("โปรดกรอก Username"),
    contract_status: yup.string().required("โปรดเลือกสถานะ"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <SmartContractFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Username:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.username?.message} />}
                name="username"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Type Contract:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.type_contract?.message} />}
                name="type_contract"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Username:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.username_contract?.message} />}
                name="username_contract"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Date:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.DateTime {...field} showYearDropdown showMonthDropdown errors={errors.username_date?.message} />}
                name="username_date"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="input_type_wrap">
          <div className="itw_left_box">Status :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "In-Review",
                    },
                    {
                      value: "2",
                      label: "Active",
                    },
                    {
                      value: "3",
                      label: "Inactive",
                    },
                    {
                      value: "4",
                      label: "Cancel",
                    },
                  ]}
                  errors={errors.contract_status?.message}
                />
              )}
              name="contract_status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard theme_red label="Delete" disabled={initialValues && initialValues.is_center} onClick={onDelete} />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </SmartContractFormStyled>
  );
};

SmartContractForm.propTypes = {};

export default SmartContractForm;
