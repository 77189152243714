export const USER_STATUS = [
  {
    label: 'งดใช้งาน',
    value: '1',
  },
  {
    label: 'ระงับ',
    value: '2',
  },
  {
    label: 'ใช้งานปกติ',
    value: '3',
  },
];

export const RENDER_USER_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'งดใช้งาน',
      };
    case '2':
      return {
        label: 'ระงับ',
      };

    case '3':
      return {
        label: 'ปกติ',
      };

    default:
      break;
  }
};

export const CO_AGENT_STATUS = [
  {
    label: 'ไม่เข้าร่วม',
    value: '1',
  },
  {
    label: 'รออนุมัติ',
    value: '2',
  },
  {
    label: 'ปฏิเสธ',
    value: '3',
  },
  {
    label: 'อนุมัติ',
    value: '4',
  },
];

export const RENDER_CO_AGENT_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ไม่เข้าร่วม',
      };
    case '2':
      return {
        label: 'รออนุมัติ',
      };
    case '3':
      return {
        label: 'ปฏิเสธ',
      };
    case '4':
      return {
        label: 'อนุมัติ',
      };
    default:
      break;
  }
};

export const BROKER_TYPE = [
  { label: 'นิติบุคคล/ฟรีแลนด์', value: '1' },
  { label: 'นามบริษัท', value: '2' },
];

export const GENDER_TYPE = [
  { label: 'ชาย', value: '1' },
  { label: 'ผู้หญฺิง', value: '2' },
];
