import styled from "styled-components";

export const TransactionCoinContainerStyled = styled.div`
  .body_table {
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
  .score_row {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    margin-top: 20px;
    border-radius: 1rem;
    padding: 1.25rem;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }
`;
