import React, { useEffect } from 'react';
import { MemberFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NATIONALITY } from 'utils/functions/nationality';
import {
  USER_STATUS,
  CO_AGENT_STATUS,
  BROKER_TYPE,
  GENDER_TYPE,
} from 'utils/functions/user';
import { Buttons, Inputs } from 'components';

const MemberForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    first_name: yup.string().required('โปรดกรอกชื่อ'),
    // username: yup.string().required("โปรดกรอก Username"),
    // user_credit: yup.string().required("โปรดกรอก Credit"),
    // user_coin: yup.string().required("โปรดกรอก Merge Coin"),
    status: yup.string().required('โปรดเลือกสถานะ'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <MemberFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Email :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField {...field} errors={errors.email?.message} />
                )}
                name="email"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Firstname :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.first_name?.message}
                  />
                )}
                name="first_name"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Lastname :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.last_name?.message}
                  />
                )}
                name="last_name"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Phone number :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.phone_number?.message}
                  />
                )}
                name="phone_number"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Nationality :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={NATIONALITY}
                    errors={errors.nationality?.message}
                  />
                )}
                name="nationality"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Gender :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={GENDER_TYPE}
                    errors={errors.gender?.message}
                  />
                )}
                name="gender"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Birthday :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DateTime
                    {...field}
                    errors={errors.birthday?.message}
                  />
                )}
                name="birthday"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Broker type :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={BROKER_TYPE}
                    errors={errors.broker_type?.message}
                  />
                )}
                name="broker_type"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Co agent status :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={CO_AGENT_STATUS}
                    errors={errors.coagent_status?.message}
                  />
                )}
                name="coagent_status"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_type_wrap">
          <div className="itw_left_box">Status :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={USER_STATUS}
                  errors={errors.status?.message}
                />
              )}
              name="status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>
        {initialValues && initialValues.id_card_img_path && (
          <img
            className="preview_img"
            alt="preview"
            src={initialValues.id_card_img_path}
          />
        )}
        {initialValues && initialValues.dbd_img_path && (
          <img
            className="preview_img"
            alt="preview"
            src={initialValues.dbd_img_path}
          />
        )}
        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard
                theme_red
                label="Delete"
                disabled={initialValues && initialValues.is_center}
                onClick={onDelete}
              />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </MemberFormStyled>
  );
};

MemberForm.propTypes = {};

export default MemberForm;
