import React from 'react';
import { TransactionCoinDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import moment from 'moment';
import {
  RENDER_ACTION_TYPE,
  RENDER_TRANSACTION_METHOD,
} from 'utils/functions/transaction';
import { portalService } from 'apiServices';
import { Forms, Displays } from 'components';

class TransactionCoinDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_MERGE_COIN_TRANSACTION_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    let params = {
      status: values.status,
    };
    if (values.status === '2') {
      params.reason_rejected = values.reason_rejected;
    }
    let res = await portalService.PUT_MERGE_COIN_TRANSACTION_UPDATE(
      this.props.router.params.id,
      params
    );
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
      });
      this.props.router.navigate(ROUTE_PATH.TRANSACTION_COIN);
    }
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { isLoading, initialValues } = this.state;
    return (
      <TransactionCoinDetailContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="data_group_block">
              <div className="dg_title">Transaction Coin</div>
              <div className="dg_item_wrap">
                <Displays.LabelValue
                  label={'จำนวนเงินที่เติม'}
                  value={initialValues.amount}
                />
                <Displays.LabelValue
                  label={'Slip path'}
                  value={initialValues.slip_path || '-'}
                />
                <Displays.LabelValue
                  label={'Service'}
                  value={
                    initialValues.service
                      ? RENDER_TRANSACTION_METHOD(initialValues.service).label
                      : ''
                  }
                />
                <Displays.LabelValue
                  label={'Action Type'}
                  value={
                    initialValues.action_type
                      ? RENDER_ACTION_TYPE(initialValues.action_type).label
                      : ''
                  }
                />
                <Displays.LabelValue
                  label={'Reject'}
                  value={initialValues.reason_rejected || '-'}
                />
                <Displays.LabelValue
                  label={'วันที่สร้าง'}
                  value={moment(initialValues.created_at).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                />
              </div>
            </div>
            <Forms.TransactionCoinForm
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
            />
          </>
        )}
      </TransactionCoinDetailContainerStyled>
    );
  }
}

export default withRouter(TransactionCoinDetailContainer);
