import React from 'react';
import { MemberContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scrollToTopPageFN } from 'utils/functions/scrollTopPage';
import { portalService } from 'apiServices';
import queryString from 'query-string';
import { NATIONALITY, RENDER_NATIONALITY } from 'utils/functions/nationality';
import {
  RENDER_USER_STATUS,
  RENDER_CO_AGENT_STATUS,
  CO_AGENT_STATUS,
} from 'utils/functions/user';
import { Displays, Tables, Cards, Inputs } from 'components';

class MemberContainer extends React.Component {
  state = {
    isLoading: true,
    perPage: 10,
  };

  componentDidMount() {
    const { perPage } = this.state;
    scrollToTopPageFN();
    const query = this.props.router.location.search || `?limit=${perPage}`;
    this.fetchData(query);
  }

  fetchData = async (stringQuery) => {
    let res = await portalService.GET_BROKER_LIST(stringQuery);
    if (res && res.status === 200) {
      this.setState({
        data: res.data.docs,
        pagination: {
          last_page: res.data.totalPages,
          current_page: res.data.page,
          totalDocs: res.data.totalDocs,
        },
        isLoading: false,
      });
    }
  };

  handleClickCreate = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.MEMBER}/${e}`);
  };

  handlePageClick = (e) => {
    const { filterQuery, perPage } = this.state;
    this.handleJoinQuery(filterQuery, perPage, e.selected + 1);
  };

  handleFilterKeyword = (e) => {
    const { perPage } = this.state;
    this.setState({
      filterQuery: e,
    });
    this.handleJoinQuery(e, perPage, 1);
  };

  handleJoinQuery = (query, per_page, page) => {
    let joinQuery = query
      ? `${query}&limit=${per_page}&page=${page}`
      : `?limit=${per_page}&page=${page}`;
    this.setState({
      isLoading: true,
    });
    this.props.router.navigate(`${ROUTE_PATH.MEMBER}${joinQuery}`);
    this.fetchData(joinQuery);
  };

  render() {
    const { router } = this.props;
    const { isLoading, pagination, data } = this.state;
    return (
      <MemberContainerStyled>
        <Inputs.InputFilter
          theme_standard_input_filter
          dataFilter={dataFilter(router.location?.search)}
          btnLabel="ค้นหา"
          onQueryFilter={this.handleFilterKeyword}
        />
        {isLoading ? (
          <Displays.Loading theme_standard_loading heightLayout="324px" />
        ) : (
          <>
            <div className="score_row">
              <Cards.ScoreBlock
                label={'จำนวน User'}
                value={pagination?.totalDocs}
              />
            </div>
            <div className="body_table">
              {/* <div className="btn_create">
                <div className="w120">
                  <Buttons.BgIconPrefix
                    label="New"
                    icon={<MdAdd size={18} />}
                    onClick={() => this.handleClickCreate('create')}
                  />
                </div>
              </div> */}
              <Tables.Standard
                columns={columns({
                  handleClickDetail: this.handleClickCreate,
                })}
                data={data}
                showPagination
                pagination={pagination}
                handlePageClick={this.handlePageClick}
              />
            </div>
          </>
        )}
      </MemberContainerStyled>
    );
  }
}

const dataFilter = (query) => {
  let initialValues = queryString.parse(query);
  return [
    {
      accessor: 'email',
      length: '311px',
      filter: {
        fieldQuery: 'email',
        defaultValue: initialValues['email'],
        type: 'text',
        label: 'Email',
        placeholder: 'Email',
      },
    },
    {
      accessor: 'name',
      length: '311px',
      filter: {
        fieldQuery: 'name',
        defaultValue: initialValues['name'],
        type: 'text',
        label: 'Name',
        placeholder: 'Name',
      },
    },
    {
      accessor: 'nationality',
      length: '311px',
      filter: {
        fieldQuery: 'nationality',
        defaultValue: initialValues['nationality'],
        type: 'dropdown',
        label: 'nationality',
        placeholder: '',
        options: NATIONALITY,
      },
    },
    {
      accessor: 'coagent_status',
      length: '311px',
      filter: {
        fieldQuery: 'coagent_status',
        defaultValue: initialValues['coagent_status'],
        type: 'dropdown',
        label: 'Co-agent',
        placeholder: '',
        options: CO_AGENT_STATUS,
      },
    },
  ];
};

const columns = ({ handleClickDetail }) => [
  {
    Header: <div style={{ textAlign: 'left' }}>อีเมล</div>,
    accessor: 'email',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>ชื่อ</div>,
    accessor: 'first_name',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>นามสกุล</div>,
    accessor: 'last_name',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>เบอร์โทร</div>,
    accessor: 'phone_number',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>สัญชาติ</div>,
    accessor: 'nationality',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_NATIONALITY(props.value).label : ''}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>โคเอเจน</div>,
    accessor: 'coagent_status',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_CO_AGENT_STATUS(props.value).label : ''}
      </div>
    ),
  },
  {
    Header: <div style={{ textAlign: 'left' }}>สถานะ</div>,
    accessor: 'status',
    Cell: (props) => (
      <div onClick={() => handleClickDetail(props.row.original._id)}>
        {props.value ? RENDER_USER_STATUS(props.value).label : ''}
      </div>
    ),
  },
];

export default withRouter(MemberContainer);
