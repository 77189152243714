import React, { useEffect } from 'react';
import { TransactionCoinFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TRANSACTION_STATUS } from 'utils/functions/transaction';
import { Inputs, Buttons } from 'components';

const TransactionCoinForm = ({
  onSubmit,
  onCancel,
  onDelete,
  initialValues,
}) => {
  const schema = yup.object().shape({
    status: yup.string().required('กรุณาเลือกสถานะ'),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchStatus = watch('status');

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TransactionCoinFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body_form_campaign">
          <div className="input_type_wrap">
            <div className="itw_left_box">สถานะ</div>
            <div className="itw_right_box">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    disabled={initialValues.status !== '1'}
                    options={TRANSACTION_STATUS}
                    errors={errors.status?.message}
                  />
                )}
                name="status"
                // defaultValue={{ label: '1', value: '1' }}
              />
            </div>
          </div>
          {watchStatus && watchStatus === '2' && (
            <div className="input_type_wrap">
              <div className="itw_left_box">เหตุผลการยกเลิก</div>
              <div className="itw_right_box">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Inputs.TextField
                      {...field}
                      // placeholder={t('test')}
                      errors={errors.reason_rejected?.message}
                    />
                  )}
                  name="reason_rejected"
                  defaultValue=""
                />
              </div>
            </div>
          )}
          <div className="btn_zone">
            <div className="w120">
              <Buttons.BgStandard
                theme_gray
                label="Cancel"
                onClick={onCancel}
              />
            </div>
            <div className="w120">
              <Buttons.BgStandard
                type="submit"
                label="Save"
                // disabled={initialValues && !initialValues.is_edit}
              />
            </div>
          </div>
        </div>
      </form>
    </TransactionCoinFormStyled>
  );
};

TransactionCoinForm.propTypes = {};

export default TransactionCoinForm;
