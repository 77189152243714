import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BtnIconPrefixStyled } from './styled';

const BtnIconPrefix = ({
  theme_standard,
  type = 'button',
  icon,
  label,
  onClick,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <BtnIconPrefixStyled>
      <div className={customClass}>
        <button
          type={type}
          disabled={disabled}
          className="btn"
          onClick={onClick}
        >
          {icon}
          {label}
        </button>
      </div>
    </BtnIconPrefixStyled>
  );
};

BtnIconPrefix.propTypes = {};

export default BtnIconPrefix;
