import React from 'react';
import { AssetDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';
import { AlertControl } from 'components/Modals/Alert';
import { Forms, Displays } from 'components';

class AssetDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_ASSET_DETAIL(this.props.router.params.id);
    console.log('data', res.data);
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    if (this.props.router.params.id === 'create') {
      let params = {
        ...values,
      };
      let res = await portalService.POST_ASSET_CREATE(params);
      if (res && res.status === 200) {
        toast.success('Create success');
        this.props.router.navigate(ROUTE_PATH.ASSET);
      } else {
        this.setState({
          isBgLoading: false,
        });
        toast.error('Create failed');
      }
    } else {
    }
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  handleDelete = () => {
    AlertControl.show({
      title: 'คุณต้องการลบใช่หรือไม่',
      btnLabel1: 'ไม่',
      btnLabel2: 'ใช่',
      onEvent2: async () => {
        this.setState({
          isBgLoading: true,
        });
        let res = await portalService.DELETE_ASSET_DETAIL(
          this.props.router.params.id
        );
        if (res && res.status === 200) {
          this.setState({
            isBgLoading: false,
          });
          toast.error('ลบสำเร็จ');
          this.props.router.navigate(ROUTE_PATH.ASSET);
        } else {
          this.setState({
            isBgLoading: false,
          });
          toast.error('ลบไม่สำเร็จ');
        }
      },
    });
  };

  render() {
    const { initialValues, isLoading, isBgLoading } = this.state;
    return (
      <AssetDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading heightLayout="324px" />
          </div>
        ) : (
          <>
            <Forms.AssetForm
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              onDelete={this.handleDelete}
              initialValues={initialValues}
            />
          </>
        )}
      </AssetDetailContainerStyled>
    );
  }
}

export default withRouter(AssetDetailContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(AaPlatContainer)));
