import React from 'react';
import { MemberDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Forms, Displays } from 'components';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';

class MemberDetailContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    if (this.props.router.params.id !== 'create') {
      this.fetchData();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  fetchData = async () => {
    let res = await portalService.GET_BROKER_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res =
      this.props.router.params.id === 'create'
        ? await portalService.POST_BROKER_CREATE(params)
        : await portalService.PUT_BROKER_UPDATE(
            this.props.router.params.id,
            params
          );
    if (res && res.status === 200) {
      toast.success('Create success');
      this.props.router.navigate(ROUTE_PATH.MEMBER);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('update fail');
    }
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { initialValues, isLoading } = this.state;
    return (
      <MemberDetailContainerStyled>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading theme_standard_loading heightLayout="324px" />
          </div>
        ) : (
          <>
            <Forms.MemberForm
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              initialValues={initialValues}
            />
          </>
        )}
      </MemberDetailContainerStyled>
    );
  }
}

export default withRouter(MemberDetailContainer);
