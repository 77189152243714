import React from "react";
import { AdvertingFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const AdvertingForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    campain_name: yup.string().required("โปรดกรอก Username"),
    campain_click: yup.string().required("โปรดกรอก Click"),
    campain_date: yup.string().required("โปรดกรอกวันที่"),
    campain_status: yup.string().required("โปรดเลือกสถานะ"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
    // reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <AdvertingFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Campain Name:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.campain_name?.message} />}
                name="campain_name"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Click:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.TextField {...field} errors={errors.campain_click?.message} />}
                name="campain_click"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Date:</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => <Inputs.DateTime {...field} showYearDropdown showMonthDropdown errors={errors.campain_date?.message} />}
                name="campain_date"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="input_type_wrap">
          <div className="itw_left_box">Status :</div>
          <div className="itw_right_box">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Dropdown
                  {...field}
                  options={[
                    {
                      value: "1",
                      label: "In-Review",
                    },
                    {
                      value: "2",
                      label: "Active",
                    },
                    {
                      value: "3",
                      label: "Inactive",
                    },
                    {
                      value: "4",
                      label: "Cancel",
                    },
                  ]}
                  errors={errors.campain_status?.message}
                />
              )}
              name="campain_status"
              // defaultValue={{ label: '1', value: '1' }}
            />
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard theme_red label="Delete" disabled={initialValues && initialValues.is_center} onClick={onDelete} />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </AdvertingFormStyled>
  );
};

AdvertingForm.propTypes = {};

export default AdvertingForm;
