import React from 'react';
import { TransactionCreditDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import moment from 'moment';
import {
  RENDER_ACTION_TYPE,
  RENDER_TRANSACTION_METHOD,
  RENDER_TRANSACTION_STATUS,
} from 'utils/functions/transaction';
import { portalService } from 'apiServices';
import { Displays } from 'components';

class TransactionCreditDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await portalService.GET_CREDIT_POINT_TRANSACTION_DETAIL(
      this.props.router.params.id
    );
    if (res && res.status === 200) {
      this.setState({
        initialValues: res.data,
        isLoading: false,
      });
    }
  };

  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.TRANSACTION_CREDIT);
  };

  handleCancel = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { isLoading, initialValues } = this.state;
    return (
      <TransactionCreditDetailContainerStyled>
        {isLoading ? (
          <Displays.BGLoading />
        ) : (
          <>
            <div className="data_group_block">
              <div className="dg_title">Transaction Coin</div>
              <div className="dg_item_wrap">
                <Displays.LabelValue
                  label={'จำนวนเงินที่เติม'}
                  value={initialValues.amount}
                />
                <Displays.LabelValue
                  label={'Service'}
                  value={RENDER_TRANSACTION_METHOD(initialValues.service).label}
                />
                <Displays.LabelValue
                  label={'Action Type'}
                  value={RENDER_ACTION_TYPE(initialValues.action_type).label}
                />
                <Displays.LabelValue
                  label={'วันที่สร้าง'}
                  value={moment(initialValues.created_at).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                />
                <Displays.LabelValue
                  label={'สถานะ'}
                  value={RENDER_TRANSACTION_STATUS(initialValues.status).label}
                />
              </div>
            </div>
          </>
        )}
      </TransactionCreditDetailContainerStyled>
    );
  }
}

export default withRouter(TransactionCreditDetailContainer);
