import styled from 'styled-components';

export const DateGroupStyled = styled.div`
  .date_group_wrap {
    display: flex;
    column-gap: 10px;
    .dg_input_wrap {
      min-width: 150px;
    }
  }

  .theme_standard {
  }
`;
