import React, { useEffect, useState } from 'react';
import { AssetFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import address from 'utils/jsons/thailandAddress/address.json';
import { ASSET_TYPE, ASSET_SERVICE } from 'utils/functions/asset';
import { Buttons, Inputs } from 'components';

const AssetForm = ({ initialValues, onCancel, onDelete, onSubmit }) => {
  const schema = yup.object().shape({
    title: yup.string().required('โปรดกรอก Asset name'),
    type: yup.string().required('โปรดกรอก Type'),
    // asset_region: yup.string().required("โปรดกรอกภูมิภาค"),
    // username: yup.string().required("โปรดกรอก Username"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const provinceOptions = address.reduce((result, data, i) => {
    if (i === 0) {
      result.push({ label: data.province, value: data.province });
    } else {
      let find = result.find((e) => e.value === data.province);
      if (!find) {
        result.push({ label: data.province, value: data.province });
      }
    }
    return result;
  }, []);

  const _handleChangeProvince = (e) => {
    setValue('district', '');
    setValue('sub_district', '');
    setValue('zipcode', '');
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e) {
          let find = result.find((e) => e.value === data.amphoe);
          if (!find) {
            result.push({
              label: `${data.amphoe}`,
              value: data.amphoe,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    setValue('sub_district', '');
    setValue('zipcode', '');
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e) {
          let find = result.find((e) => e === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e);
    if (find) {
      setValue('zipcode', String(find.zipcode));
    }
  };

  return (
    <AssetFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <div className="left_box">Name :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField {...field} errors={errors.title?.message} />
                )}
                name="title"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Service :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={ASSET_SERVICE}
                    errors={errors.service?.message}
                  />
                )}
                name="service"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Type :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={ASSET_TYPE}
                    errors={errors.type?.message}
                  />
                )}
                name="type"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Province :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={provinceOptions}
                    onChaneCustom={_handleChangeProvince}
                    errors={errors.province?.message}
                  />
                )}
                name="province"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">District :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={_districtOption}
                    onChaneCustom={_handleChangeDistrict}
                    errors={errors.district?.message}
                  />
                )}
                name="district"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Sub district :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={_subdistrictOption}
                    onChaneCustom={_handleChangeSubDistrict}
                    errors={errors.sub_district?.message}
                  />
                )}
                name="sub_district"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Zipcode :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    errors={errors.zipcode?.message}
                  />
                )}
                name="zipcode"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="input_wrap">
          <div className="left_box">Price :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="number"
                    errors={errors.asset_price?.message}
                  />
                )}
                name="asset_price"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Commission(%) :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="number"
                    errors={errors.percent_commission?.message}
                  />
                )}
                name="percent_commission"
                defaultValue=""
              />
            </div>
          </div>
        </div>
        <div className="input_wrap">
          <div className="left_box">Commission(฿) :</div>
          <div className="right_box">
            <div className="w50">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="number"
                    errors={errors.price_commission?.message}
                  />
                )}
                name="price_commission"
                defaultValue=""
              />
            </div>
          </div>
        </div>

        <div className="btn_zone">
          <div className="w120">
            <Buttons.BgStandard theme_gray label="Cancel" onClick={onCancel} />
          </div>
          {initialValues && (
            <div className="w120">
              <Buttons.BgStandard
                theme_red
                label="Delete"
                disabled={initialValues && initialValues.is_center}
                onClick={onDelete}
              />
            </div>
          )}
          <div className="w120">
            <Buttons.BgStandard
              type="submit"
              label="Save"
              // disabled={initialValues && !initialValues.is_edit}
            />
          </div>
        </div>
      </form>
    </AssetFormStyled>
  );
};

AssetForm.propTypes = {};

export default AssetForm;
