import { useEffect, useState } from "react";
import { SidebarWidgetStyled } from "./styled";
import systemRoutes from "navigations/systemRoutes";
import { useNavigate, useLocation } from "react-router-dom";
import { AvatarNavWidget } from "widgets";
import { Icons } from "components";

const SidebarWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [_isExpand, _setIsExpand] = useState(true);
  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split("/");
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.path);
  };

  const _handleClickExpand = () => {
    _setIsExpand(!_isExpand);
  };

  return (
    <SidebarWidgetStyled isExpand={_isExpand}>
      <div className="btn_expand" onClick={_handleClickExpand}>
        <Icons.ArrowLeftRight />
      </div>
      <div className="s_logo_sec">
        {/* Merge Thailand */}
        <img className="sidebar_logo" alt="MiSUMi logo" src="/assets/images/logos/logo-2.png" />
      </div>
      <div className="avatar_nav_wrap">
        <AvatarNavWidget isExpand={_isExpand} />
      </div>
      <ul className="s_menu_sec">
        {systemRoutes &&
          systemRoutes
            .filter((f) => !f.notShowSidebar)
            .map((e, i) => (
              <li key={i} className="s_menu_item" onClick={() => _handleClickMenu(e)}>
                <div className="s_icon_wrap">{e.icon && e.icon(_active === e.key ? true : false)}</div>
                <div className="s_label">{e.name}</div>
              </li>
            ))}
      </ul>
    </SidebarWidgetStyled>
  );
};

export default SidebarWidget;
