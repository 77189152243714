import React from 'react';
import { LoginContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { connect } from 'react-redux';
import { Forms, Displays } from 'components';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { portalService } from 'apiServices';
import { toast } from 'react-toastify';

class LoginContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = async (values) => {
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
    };
    let res = await portalService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      toast.success('Login success');
      this.props.router.navigate(ROUTE_PATH.MEMBER);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('Login failed');
    }
  };

  render() {
    const { isBgLoading } = this.state;

    return (
      <LoginContainerStyled>
        <div className="form_layout">
          <Displays.BGLoading visible={isBgLoading} />
          <Forms.LoginForm onSubmit={this.handleSubmit} />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
